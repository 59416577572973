import React from "react"

const GiftRegistry = ({}) => (
  <section id="registry" style={{ marginTop: "50px" }}>
    <div className="container">
      <div className="row">
        <div className="col-md-offset-2 col-md-8 text-center">
          <div style={{ visibility: "visible" }}>
            <h2>GIFT REGISTRY</h2>
            <p>
              Click on the following image to navigate to the registry website
            </p>
            <p>
              <a href="https://www.myregistry.com/wedding-registry/bill-stavroulakis-and-efseveia-katsoulieri-south-san-francisco-ca/3257928">
                <img src="images/my-registry.png" alt="" />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

GiftRegistry.propTypes = {}

GiftRegistry.defaultProps = {}

export default GiftRegistry
